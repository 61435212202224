import { useLocalStorage } from '@vueuse/core'

type WizardState = {
  title?: string
  url?: string
  routeName?: string
}

type ResumableWizardState = {
  title: string
  url: string
  routeName: string
}

type UseWizardState = {
  canResume: ComputedRef<boolean>
  activeStates: ComputedRef<ResumableWizardState[]>
  clear: () => void
}

export default function (isFinished?: ComputedRef<boolean>): UseWizardState {
  const state = useLocalStorage<WizardState | undefined>(
    'active_wizard_state',
    {},
  )

  if (isFinished) {
    const route = useRoute()
    const id = useRouteParamString('id')
    const key = computed(() => route.name?.toString() + id.value)
    watch(isFinished, (finished) => {
      if (!key) {
        return
      }
      if (finished) {
        state.value = {}
      }
    })

    if (key.value && route.name) {
      state.value = {
        title: document.title,
        url: route.path,
        routeName: route.name.toString(),
      }
    }
  }

  const activeStates = computed<ResumableWizardState[]>(() => {
    if (state.value?.url && state.value.title && state.value.routeName) {
      return [
        {
          title: state.value.title,
          url: state.value.url,
          routeName: state.value.routeName,
        },
      ]
    }

    return []
  })

  const canResume = computed(() => !!activeStates.value.length)

  function clear() {
    state.value = null
  }

  return {
    canResume,
    clear,
    activeStates,
  }
}
